<template>
  <div>
    <div class="mb-4">
      <v-card>
        <v-card-text>
          <v-row class="justify-space-between align-md-center">
            <v-col
              sm="6"
              md="4"
              xl="4"
              cols="12"
            >
              <h2>Sambutan</h2>
              <small style="white-space: normal;">Halaman untuk menambahkan sambutan</small>
            </v-col>
            <v-col
              sm="6"
              md="3"
              xl="3"
              cols="12"
            >
              <v-btn
                v-if="isEmpty"
                color="primary"
                large
                width="100%"
                @click="showFormAdd"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon> TAMBAH DATA
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-row v-if="!isLoadingCard">
      <v-col
        v-for="greeting,index in greetings"
        :key="index"
        sm="6"
        md="4"
        xl="4"
        cols="12"
      >
        <v-card>
          <v-card
            class="overflow-hidden"
            style="z-index: 0"
          >
            <div
              class="pa-2 position-absolute"
              style="right: 0;z-index: 1;"
            >
              <v-spacer></v-spacer>
              <v-menu
                offset-y
                nudge-bottom="5"
                nudge-left="60"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="white"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showFormEdit(greeting.uuid)">
                    <v-list-item-title>
                      <div class="d-flex">
                        <div>
                          <v-img
                            width="16"
                            src="@/assets/icons/edit.svg"
                          ></v-img>
                        </div>

                        <small class="ml-2">Edit</small>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="confirmDestroy(greeting.uuid)">
                    <v-list-item-title>
                      <div class="d-flex">
                        <div>
                          <v-img
                            width="16"
                            src="@/assets/icons/trash-red.svg"
                          ></v-img>
                        </div>

                        <small class="ml-2 error--text mb-0">Hapus</small>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div
              class=""
              style="z-index: 0;"
            >
              <div>
                <v-img
                  :src="greeting.thumbnail"
                  height="150"
                ></v-img>
              </div>
            </div>
            <v-overlay
              :absolute="true"
              z-index="0"
              opacity="0.25"
              color="#001A33"
            ></v-overlay>
          </v-card>
          <v-card-text class="py-4">
            <div class="mb-4">
              <h2 class="font-weight-bold text-truncate mb-2">
                {{ greeting.title }}
              </h2>
              <p class="font-weight-light text-truncate mb-2">
                {{ greeting.content }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        md="4"
        cols="12"
      >
        <v-skeleton-loader
          type="image"
          :loading="true"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || greeting.thumbnail === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Sambutan': 'Edit Sambutan'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="pt-0 pb-3"
          >
            <div>
              <FileInput
                v-model="initialFile"
                :value="greeting.thumbnail"
                label="Thumbnail"
                outlined
                dense
                :disabled="false"
                :prependicon="icons.mdiImageMultipleOutline"
                @input="getFile"
              ></FileInput>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="greeting.title"
                label="Judul"
                outlined
                dense
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="greeting.content"
                label="isi Sambutan"
                outlined
                dense
              >
              </v-textarea>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Sambutan"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="greeting.thumbnail"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { mdiClose, mdiImageMultipleOutline, mdiPlus, mdiDotsVertical } from '@mdi/js'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'

export default {
  name: 'Greeting',
  components: {
    ModalDialog,
    ImageOverlay,
    ConfirmDialog,
    FileInput,
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiPlus,
        mdiImageMultipleOutline,
        mdiDotsVertical,
      },
      width: 600,
      kadis: [],
      kadis_uuid: null,
      page: 1,
      imageOverlay: false,
      isLoadingCard: false,
      isLoadingButton: false,
      isLoadingTable: true,
      isEmpty: false,
      totalItems: 10,
      totalPages: 0,
      search: '',
      dialog: '',
      service: 'greetingheaddepartment',
      greetings: [],
      greeting: {
        thumbnail: null,
        title: '',
        content: '',
      },
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Judul', value: 'title' },
        { text: 'Isi Sambutan', value: 'content' },
        { text: 'Thumbnail', value: 'thumbnail' },
        { text: 'Aksi', value: 'actions' },
      ],
      filterQuery: {
        search: '',
      },
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.greeting.thumbnail === 'string') {
          return new File([this.greeting.thumbnail], this.greeting.thumbnail, { type: 'text/plain' })
        }

        return this.greeting.thumbnail
      },
      set() {
        return this.greeting.thumbnail
      },
    },
  },
  watch: {
    greeting: {
      handler() {
        const valid = []
        const requiredField = ['title', 'content']
        Object.entries(this.greeting).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listGreeting(this.filterQuery)
      },
    },
  },
  beforeMount() {
    this.getHeaddepartmentUuid()
  },
  mounted() {
    this.listGreeting()
  },
  methods: {
    resetForm() {
      this.greeting.thumbnail = null
      this.greeting.title = ''
      this.greeting.content = ''
    },
    showFormAdd() {
      this.dialog = 'add'
      this.greeting = {
        thumbnail: null,
        title: '',
        content: '',
      }
      this.modalDialog = true
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.greeting = {
        thumbnail: null,
        title: '',
        content: '',
      }
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.greeting = data.data
      })
      this.modalDialog = true
    },
    getFile(data) {
      this.greeting.thumbnail = data
    },
    async listGreeting(params = {}) {
      this.isLoadingCard = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        kadis_uuid: this.kadis_uuid,
      }).then(({ data }) => {
        this.greetings = data.data.map((greeting, index) => ({
          ...greeting,
          index: index + 1,
        }))
        this.isEmpty = false
        if (this.greetings.length < 1) {
          this.isEmpty = true
        }
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingTable = false
      this.isLoadingCard = false
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('title', this.greeting.title)
      this.formData.append('content', this.greeting.content)
      this.formData.append('thumbnail', this.greeting.thumbnail)
      // this.formData.append('kadis_uuid', this.kadis_uuid)
      if (typeof this.greeting.thumbnail === 'string') {
        this.formData.delete('thumbnail')
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.greeting.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.greeting = {
            thumbnail: null,
            title: '',
            content: '',
          }
        },
      )
      await this.listGreeting(this.filterQuery)
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('title', this.greeting.title)
      this.formData.append('content', this.greeting.content)
      this.formData.append('thumbnail', this.greeting.thumbnail)
      this.formData.append('kadis_uuid', this.kadis_uuid)

      if (this.greeting.thumbnail === null) {
        this.showSnackbar({
          text: 'please add thumbnail first !!',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.greeting = {
            thumbnail: null,
            title: '',
            content: '',
          }
        },
      )
      await this.listGreeting(this.filterQuery)
      await this.resetForm()
    },
    confirmDestroy(uuid) {
      this.greeting = {
        thumbnail: null,
        title: '',
        content: '',
      }
      this.confirmDialog = true
      this.greeting.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.greeting.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.greeting = {
            thumbnail: null,
            title: '',
            content: '',
          }
        },
      )
      await this.listGreeting(this.filterQuery)
    },
    getHeaddepartmentUuid() {
      this.kadis = JSON.parse(localStorage.getItem('user')).user.details
      this.kadis.forEach(el => {
        this.kadis_uuid = el.uuid
      })

      return true
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listGreeting(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    async showImage(uuid) {
      await this.$services.ApiServices.get('greeting', uuid).then(
        ({ data }) => {
          this.greeting = data.data
          this.greeting.thumbnail = data.data.thumbnail
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },

    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },
  },
}
</script>

<style>
</style>
